const wordingMarkPiketing = [
    {
        value: 'ON',
        wording: 'Obligatoire',
    },
    {
        value: 'OFF',
        wording: 'Non applicable',
    },
    {
        value: 'OPTIONAL',
        wording: 'Facultative',
    },
]

export default wordingMarkPiketing
