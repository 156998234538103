<template>
    <div>
        <div class="uk-padding-small d-flex justify-content-end">
            <vk-button
                type="primary"
                class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                @click="createNewCode"
            >
                Créer
            </vk-button>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                ref="vueTable"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                }"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'actions'" class="uk-text-center d-flex">
                        <vk-button
                            title="Modifier marquage/piquettage"
                            type="primary"
                            class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                            @click="() => updateMarquagePiquettage(props.row)"
                        >
                            <i class="fa-solid fa-pen-to-square"></i>
                        </vk-button>

                        <vk-button
                            title="Supprimer code standard"
                            type="danger"
                            class="ms-1 uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                            @click="() => deleteCode(props.row)"
                        >
                            <i class="fa-solid fa-trash"></i>
                        </vk-button>
                    </div>

                    <div v-else-if="props.column.field === 'markingAndPicketingValue'">
                        <span>{{ formatApplicability(props.row.markingAndPicketingValue) }}</span>
                    </div>
                </template>
            </vue-good-table>
        </div>

        <CodeStandardModalCreate :toUpdate="toUpdate" ref="newCode" @close="toUpdate = null" @saved="loadItems" />

        <blank-modal ref="modalDelete" :modal-id="$util.htmlId()" @close="() => (toDelete = null)">
            <template #modalHeader>
                <legend class="uk-legend">Suppression</legend>
            </template>
            <template #modalBody> Voulez-vous supprimer ce code standard? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="confirmDeleteCode">
                    Oui
                    <div v-if="isLoading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import CodeStandardModalCreate from '@/components/code-standards/modal-create'
import markPicket from '@/Enum/MarquagePiquetage'

export default {
    name: 'CodeStandards',
    components: { CodeStandardModalCreate },
    data: () => ({
        isLoading: false,
        toUpdate: null,
        toDelete: null,
        serverParams: {
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
        columns: [
            {
                label: 'Code standard',
                field: 'value',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Marquage/Piquetage',
                field: 'markingAndPicketingValue',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            },
        ],
        createMode: false,
    }),
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true
            backendApi
                .getListCodeStandards(this.serverParams)
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .finally(() => (this.isLoading = false))
        },
        createNewCode() {
            this.$refs.newCode.open()
        },

        updateMarquagePiquettage(item) {
            this.toUpdate = item
            this.$refs.newCode.open()
        },

        formatApplicability(value) {
            const item = markPicket.find((el) => el.value == value)
            return item.wording
        },

        deleteCode(item) {
            this.toDelete = item
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },

        confirmDeleteCode() {
            this.isLoading = true
            backendApi
                .deleteStandardCode(this.toDelete.id)
                .then(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Code standard supprimé avec succès',
                    })
                })
                .finally(() => {
                    this.isLoading = false
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.loadItems()
                })
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>
