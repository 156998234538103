<template>
    <vk-modal :show.sync="show" stuck>
        <vk-modal-close @click="close"></vk-modal-close>
        <vk-modal-title v-if="toUpdate" v-model="formData">
            {{ `Modification du code standard : ${formData.code}` }}
        </vk-modal-title>
        <vk-modal-title v-else> Nouveau code standard </vk-modal-title>

        <div>
            <div class="form-group mb-4">
                <label for="code-standard">Code standard : </label>
                <input v-if="!toUpdate" v-model="formData.code" class="form-control" type="text" id="code-standard" />
                <span v-else class="form-control" type="text" id="code-standard">{{ formData.code }}</span>
                <span class="text-danger" v-if="error.code"> {{ error.code }}</span>
            </div>

            <div class="form-group">
                <label for="markingAndPicketingValue">Marquage/Piquetage : </label>
                <select v-model="formData.markingAndPicketingValue" class="form-select" id="markingAndPicketingValue">
                    <option
                        v-for="opt in markingAndPicketingValue"
                        :key="`markingAndPicketingValue-create-${opt.value}`"
                        :value="opt.value"
                    >
                        {{ opt.wording }}
                    </option>
                </select>
                <span class="text-danger" v-if="error.markingAndPicketingValue">
                    {{ error.markingAndPicketingValue }}</span
                >
            </div>
        </div>

        <div class="mt-4 d-flex justify-content-end">
            <div v-if="loading" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <vk-button
                type="primary"
                class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                @click="save"
                v-else
            >
                <span>Enregistrer</span>
            </vk-button>
        </div>
    </vk-modal>
</template>

<script>
import backendApi from '@/backend/api'
import markPicket from '@/Enum/MarquagePiquetage'

export default {
    name: 'CodeStandardModalCreate',
    props: {
        toUpdate: {
            required: false,
        },
        onSaved: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data: () => ({
        formData: {
            code: null,
            markingAndPicketingValue: null,
        },
        loading: false,
        error: {
            code: null,
            markingAndPicketingValue: null,
        },
        show: false,
        markingAndPicketingValue: markPicket,
    }),

    computed: {
        isFormValid() {
            return this.formData.code && this.formData.markingAndPicketingValue
        },
    },

    watch: {
        toUpdate: {
            handler(value) {
                if (value) {
                    this.formData.code = value.value
                    this.formData.markingAndPicketingValue = value.markingAndPicketingValue
                }
            },
            immediate: true,
        },
    },

    methods: {
        save() {
            if (!this.isFormValid) {
                if (!this.formData.code) this.error.code = 'Champs Code obligatoire'
                if (!this.formData.markingAndPicketingValue)
                    this.error.markingAndPicketingValue = 'Champs Marquage/Piquetage obligatoire'
            } else {
                this.loading = true
                const request = this.toUpdate
                    ? backendApi.updateStandardCode(this.toUpdate.id, this.formData)
                    : backendApi.createStandardCode(this.formData)

                request
                    .then(() => {
                        const message = this.toUpdate
                            ? 'Code standard mise à jour avec succès'
                            : 'Code standard créée avec succès'
                        this.$store.dispatch('notifications/addNotification', { status: 'success', message })
                        this.close()
                        this.$emit('saved')
                    })
                    .finally(() => (this.loading = false))
            }
        },
        reset() {
            this.loading = false
            this.formData.code = null
            this.formData.markingAndPicketingValue = null
            this.error.code = null
            this.error.markingAndPicketingValue = null
        },
        close() {
            this.show = false
            this.reset()
            this.$emit('close')
        },
        open() {
            this.show = true
        },
    },
}
</script>
